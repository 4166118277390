<template>
  <main>
    <!-- loading is true when the call is in the "joining-meeting" meeting state -->
    <div v-if="loading">
      <div class="loading-spinner"><loading-tile /></div>
    </div>

    <div v-else>
      <div class="wrapper">
        <div v-if="error">
          <p class="error-text">{{ error }}</p>
          <!-- refreshing will leave the call and reset the app state -->
          <button class="error-button" @click="leaveAndCleanUp">Refresh</button>
        </div>

        <div v-if="showPermissionsError">
          <permissions-error-msg :reset="leaveAndCleanUp" />
        </div>

        <div v-else>
          <div class="tile-container full-height">
            <div
              v-if="participants"
              class="participants-container"
              :style="{ backgroundColor: backgroundColor }"
            >
              <div
                v-for="p in participants"
                :key="p.session_id"
                class="participant-item p-0 m-0"
              >
                <video-tile
                  :participant="p"
                  :handle-video-click="handleVideoClick"
                  :handle-audio-click="handleAudioClick"
                  :handle-screenshare-click="handleScreenshareClick"
                  :leave-call="leaveAndCleanUp"
                  :disable-screen-share="screen && !screen?.local"
                  :is-active-speaker="activeSpeaker === p.session_id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import daily from "@daily-co/daily-js";

import VideoTile from "./VideoTile.vue";
import LoadingTile from "./LoadingTile.vue";
import PermissionsErrorMsg from "./PermissionsErrorMsg.vue";
import { mapGetters } from "vuex";
export default {
  name: "CallTile",
  components: {
    VideoTile,
    LoadingTile,
    PermissionsErrorMsg,
  },
  props: ["leaveCall", "name", "roomUrl", "videoChatToken"],
  data() {
    return {
      callObject: null,
      participants: null,
      count: 0,
      messages: [],
      error: false,
      loading: false,
      showPermissionsError: false,
      screen: null,
      activeSpeaker: null,
      debounceTimer: null,
      keepAliveInterval: null,
    };
  },
  computed: {
    backgroundColor() {
      return this.$store.state.backgroundColor;
    },
    ...mapGetters(["videoChatMicrophoneMuted"]),
  },
  methods: {
    setActiveSpeaker(sessionId) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.activeSpeaker = sessionId;
      }, 100);
    },
    /**
     * This is called any time a participant update registers.
     * In large calls, this should be optimized to avoid re-renders.
     * For example, track-started and track-stopped can be used
     * to register only video/audio/screen track changes.
     */
    updateParticpants(e) {
      if (!this.callObject) return;

      const p = this.callObject.participants();
      this.count = Object.values(p).length;
      this.participants = Object.values(p);

      const screen = this.participants.filter((p) => p.screenVideoTrack);
      if (screen?.length && !this.screen) {
        this.screen = screen[0];
      } else if (!screen?.length && this.screen) {
        this.screen = null;
      }
      // window.emitter.emit("screen", this.screen);
      this.$store.commit("setScreenShare", this.screen);
      this.loading = false;
    },
    // Add chat message to local message array
    updateMessages(e) {
      this.messages.push(e?.data);
    },
    // Show local error in UI when daily-js reports an error
    handleError(e) {
      this.error = e?.errorMsg;
      this.loading = false;
    },
    // Temporary show loading view while joining the call
    handleJoiningMeeting() {
      this.loading = true;
    },
    // Toggle local microphone in use (on/off)
    handleAudioClick() {
      const audioOn = this.callObject.localAudio();
      this.callObject.setLocalAudio(!audioOn);

      // Update local microphone state
      this.$store.commit("setMicrophoneMuted", audioOn);

      // Update microphone status through Vuex and send to server
      this.$store.dispatch("updateMicrophoneStatus", {
        isMuted: audioOn,
      });
    },
    // Toggle local camera in use (on/off)
    handleVideoClick() {
      const videoOn = this.callObject.localVideo();
      this.callObject.setLocalVideo(!videoOn);
    },
    // Show permissions error in UI to alert local participant
    handleDeviceError() {
      this.showPermissionsError = true;
    },
    // Toggle screen share
    handleScreenshareClick() {
      if (this.screen?.local) {
        this.callObject.stopScreenShare();
        this.screen = null;
      } else {
        this.callObject.startScreenShare();
      }
    },
    /**
     * Send broadcast message to all remote call participants.
     * The local participant updates their own message history
     * because they do no receive an app-message Daily event for their
     * own messages.
     */
    sendMessage(text) {
      // Attach the local participant's username to the message to be displayed in ChatTile.vue
      const local = this.callObject.participants().local;
      const message = { message: text, name: local?.user_name || "Guest" };
      this.messages.push(message);
      this.callObject.sendAppMessage(message, "*");
    },
    // leave call, destroy call object, and reset local state values
    leaveAndCleanUp() {
      // Stop the keep-alive loop first
      this.stopKeepAliveLoop();

      if (this.screen?.local) {
        this.callObject.stopScreenShare();
      }
      this.callObject.leave().then(() => {
        this.callObject.destroy();

        this.participantWithScreenshare = null;
        this.screen = null;

        // Send leave message to backend through Vuex
        this.$store.dispatch("sendLeftVideoChat");

        this.leaveCall();
      });
    },
    handleActiveSpeakerChange({ activeSpeaker }) {
      if (activeSpeaker) {
        this.setActiveSpeaker(activeSpeaker.peerId);
      } else {
        this.setActiveSpeaker(null);
      }
    },
    // Add method to handle backend mic updates
    handleBackendMicUpdate(shouldMute) {
      if (this.callObject) {
        // currentAudioState = true means mic is ON
        // shouldMute = true means mic should be MUTED (off)
        const currentAudioState = this.callObject.localAudio();
        const desiredAudioState = !shouldMute; // Convert mute flag to audio state

        if (currentAudioState === desiredAudioState) {
          // pass
        } else {
          console.log(
            "Setting microphone to",
            desiredAudioState ? "ON" : "OFF"
          );
          this.callObject.setLocalAudio(desiredAudioState);
        }
      }
    },

    // Start keep-alive loop to periodically send joined status
    startKeepAliveLoop() {
      // Clear any existing interval first
      this.stopKeepAliveLoop();

      // Send status update every 60 seconds
      this.keepAliveInterval = setInterval(() => {
        if (this.callObject) {
          const currentAudioStatus = this.callObject.localAudio();
          console.log(
            "Sending keep-alive joined status, mic:",
            currentAudioStatus ? "ON" : "MUTED"
          );

          // Send current status to backend
          this.$store.dispatch("sendJoinedVideoChat", {
            muted: !currentAudioStatus,
          });
        }
      }, 60000); // 60 seconds
    },

    // Stop the keep-alive loop
    stopKeepAliveLoop() {
      if (this.keepAliveInterval) {
        clearInterval(this.keepAliveInterval);
        this.keepAliveInterval = null;
      }
    },
  },
  mounted() {
    const option = {
      url: this.roomUrl,
      token: this.videoChatToken,
    };

    // Create instance of Daily call object
    const co = daily.createCallObject(option);
    // Assign in data obj for future reference
    this.callObject = co;

    // Join the call with the name set in the Home.vue form
    co.join({ userName: this.name });

    window.emitter.on("setMicrophone", (microphoneId) => {
      co.setInputDevicesAsync({ audioDeviceId: microphoneId });
    });

    window.emitter.on("setVideo", (videoId) => {
      co.setInputDevicesAsync({ videoDeviceId: videoId });
    });

    window.emitter.on("setSpeaker", (speakerId) => {
      co.setOutputDeviceAsync({ outputDeviceId: speakerId });
    });

    // Add call and participant event handler
    // Visit https://docs.daily.co/reference/daily-js/events for more event info
    co.on("joining-meeting", this.handleJoiningMeeting)
      .on("joined-meeting", (event) => {
        this.updateParticpants(event);
        // Send initial mic status to backend when we've actually joined
        const initialAudioStatus = co.localAudio();
        console.log("initialAudioStatus", initialAudioStatus);
        this.$store.dispatch("sendJoinedVideoChat", {
          muted: !initialAudioStatus,
        });
        // Set initial microphone state
        this.$store.commit("setMicrophoneMuted", !initialAudioStatus);

        // Start periodic keep-alive messages
        this.startKeepAliveLoop();
      })
      .on("participant-joined", this.updateParticpants)
      .on("participant-updated", this.updateParticpants)
      .on("participant-left", this.updateParticpants)
      .on("error", this.handleError)
      // camera-error = device permissions issue
      .on("camera-error", this.handleDeviceError)
      // app-message handles receiving remote chat messages
      .on("app-message", this.updateMessages);

    co.on("active-speaker-change", this.handleActiveSpeakerChange);
  },
  unmounted() {
    // Stop keep-alive loop
    this.stopKeepAliveLoop();

    if (!this.callObject) return;

    const removeListener = (event, listener) => {
      if (typeof listener === "function") {
        try {
          this.callObject.off(event, listener);
        } catch (error) {
          console.error(`Error removing listener for event ${event}:`, error);
        }
      } else {
        console.warn(`Listener for event ${event} is not a function`);
      }
    };

    // Clean-up event handlers
    removeListener("joining-meeting", this.handleJoiningMeeting);
    removeListener("joined-meeting", this.updateParticpants);
    removeListener("participant-joined", this.updateParticpants);
    removeListener("participant-updated", this.updateParticpants);
    removeListener("participant-left", this.updateParticpants);
    removeListener("error", this.handleError);
    removeListener("camera-error", this.handleDeviceError);
    removeListener("app-message", this.updateMessages);
    removeListener("active-speaker-change", this.handleActiveSpeakerChange);

    // Remove emitter event listeners
    window.emitter.off("setMicrophone");
    window.emitter.off("setVideo");
    window.emitter.off("setSpeaker");

    // If you have any other cleanup to do, do it here
  },
  watch: {
    // Watch videoChatMicrophoneMuted state for microphone control
    videoChatMicrophoneMuted(shouldMute) {
      this.handleBackendMicUpdate(shouldMute);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap");

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.tile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.participants-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: inherit;
  padding-bottom: 8px;
}
.participants-container::before,
.participants-container::after {
  content: "";
  flex: 1;
}
.participants-container::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}
.participants-container::-webkit-scrollbar-track {
  background: #51555b;
  border-radius: 12px;
}

.participants-container::-webkit-scrollbar-thumb {
  background: #184059;
  border-radius: 12px;
}
.participant-item {
  width: auto;
  display: inline-block;
}
p {
  color: white;
}
.error-text {
  color: #e71115;
}

.error-button {
  color: #fff;
  background-color: #121a24;
  border: none;
  font-size: 12px;
  border: 1px solid #050e16;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
}

.tile-container {
  margin-bottom: 20px;
}

@media screen and (max-width: 700px) {
  .participants-container {
    margin-right: 8px;
  }
}

@media (min-width: 320px) {
  .col-6 {
    width: 38%;
  }
}

@media (min-width: 340px) {
  .col-6 {
    width: 33%;
  }
}

@media (min-width: 370px) {
  .col-6 {
    width: 31%;
  }
}

@media (min-width: 380px) {
  .col-6 {
    width: 30%;
  }
}

@media (min-width: 390px) {
  .col-6 {
    width: 29%;
  }
}

@media (min-width: 400px) {
  .col-6 {
    width: 28%;
  }
}

@media (min-width: 410px) {
  .col-6 {
    width: 27.5%;
  }
}

@media (min-width: 420px) {
  .col-6 {
    width: 27%;
  }
}

@media (min-width: 430px) {
  .col-6 {
    width: 26.5%;
  }
}

@media (min-width: 440px) {
  .col-6 {
    width: 26%;
  }
}

@media (min-width: 450px) {
  .col-6 {
    width: 25.5%;
  }
}

@media (min-width: 460px) {
  .col-6 {
    width: 25%;
  }
}

@media (min-width: 470px) {
  .col-6 {
    width: 24.5%;
  }
}

@media (min-width: 480px) {
  .col-6 {
    width: 24%;
  }
}

@media (min-width: 490px) {
  .col-6 {
    width: 23.5%;
  }
}

@media (min-width: 500px) {
  .col-6 {
    width: 23%;
  }
}

@media (min-width: 510px) {
  .col-6 {
    width: 22.5%;
  }
}

@media (min-width: 520px) {
  .col-6 {
    width: 22%;
  }
}

@media (min-width: 530px) {
  .col-6 {
    width: 21.5%;
  }
}

@media (min-width: 540px) {
  .col-6 {
    width: 21%;
  }
}

@media (min-width: 550px) {
  .col-6 {
    width: 20.5%;
  }
}

@media (min-width: 560px) {
  .col-6 {
    width: 20%;
  }
}

@media (min-width: 576px) {
  .col-sm-4 {
    width: 30%;
  }
}

@media (min-width: 596px) {
  .col-sm-4 {
    width: 29%;
  }
}

@media (min-width: 616px) {
  .col-sm-4 {
    width: 28%;
  }
}

@media (min-width: 636px) {
  .col-sm-4 {
    width: 27%;
  }
}

@media (min-width: 656px) {
  .col-sm-4 {
    width: 26.5%;
  }
}

@media (min-width: 676px) {
  .col-sm-4 {
    width: 25.5%;
  }
}

@media (min-width: 696px) {
  .col-sm-4 {
    width: 24.5%;
  }
}

@media (min-width: 716px) {
  .col-sm-4 {
    width: 24%;
  }
}

@media (min-width: 736px) {
  .col-sm-4 {
    width: 23.5%;
  }
}

@media (min-width: 756px) {
  .col-sm-4 {
    width: 23%;
  }
}

@media (min-width: 768px) {
  .col-md-3 {
    width: 22.5%;
  }
}

@media (min-width: 780px) {
  .col-md-3 {
    width: 22%;
  }
}

@media (min-width: 800px) {
  .col-md-3 {
    width: 21.5%;
  }
}

@media (min-width: 820px) {
  .col-md-3 {
    width: 21%;
  }
}

@media (min-width: 840px) {
  .col-md-3 {
    width: 20.5%;
  }
}

@media (min-width: 860px) {
  .col-md-3 {
    width: 20%;
  }
}

@media (min-width: 880px) {
  .col-md-3 {
    width: 19.5%;
  }
}

@media (min-width: 900px) {
  .col-md-3 {
    width: 19%;
  }
}

@media (min-width: 920px) {
  .col-md-3 {
    width: 18.5%;
  }
}

@media (min-width: 940px) {
  .col-md-3 {
    width: 18%;
  }
}

@media (min-width: 970px) {
  .col-md-3 {
    width: 17.5%;
  }
}

@media (min-width: 992px) {
  .col-lg-3 {
    width: 26%;
  }
}

@media (min-width: 1042px) {
  .col-lg-3 {
    width: 25%;
  }
}

@media (min-width: 1082px) {
  .col-lg-3 {
    width: 24%;
  }
}

@media (min-width: 1110px) {
  .col-lg-3 {
    width: 23.5%;
  }
}

@media (min-width: 1150px) {
  .col-lg-3 {
    width: 22.5%;
  }
}

@media (min-width: 1170px) {
  .col-lg-3 {
    width: 22%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3 {
    width: 19%;
  }
}

@media (min-width: 1250px) {
  .col-xl-3 {
    width: 18%;
  }
}

@media (min-width: 1300px) {
  .col-xl-3 {
    width: 17.5%;
  }
}

@media (min-width: 1350px) {
  .col-xl-3 {
    width: 17%;
  }
}

@media (min-width: 1400px) {
  .col-xl-3 {
    width: 16.5%;
  }
}

@media (min-width: 1450px) {
  .col-xl-3 {
    width: 16%;
  }
}

@media (min-width: 1500px) {
  .col-xxl-2 {
    width: 15.5%;
  }
}

@media (min-width: 1550px) {
  .col-xxl-2 {
    width: 15%;
  }
}

@media (min-width: 1600px) {
  .col-xxl-2 {
    width: 14.5%;
  }
}

@media (min-width: 1650px) {
  .col-xxl-2 {
    width: 14%;
  }
}

@media (min-width: 1700px) {
  .col-xxl-2 {
    width: 13.5%;
  }
}

@media (min-width: 1750px) {
  .col-xxl-2 {
    width: 13%;
  }
}

@media (min-width: 1800px) {
  .col-xxl-2 {
    width: 12.5%;
  }
}

@media (min-width: 1850px) {
  .col-xxl-2 {
    width: 12%;
  }
}

@media (min-width: 1950px) {
  .col-xxl-2 {
    width: 11.5%;
  }
}

@media (min-width: 2000px) {
  .col-xxl-2 {
    width: 11%;
  }
}

@media (min-width: 2100px) {
  .col-xxl-2 {
    width: 10.5%;
  }
}

@media (min-width: 2200px) {
  .col-xxl-2 {
    width: 10%;
  }
}

@media (min-width: 2300px) {
  .col-xxl-2 {
    width: 9.5%;
  }
}

@media (min-width: 2500px) {
  .col-xxl-2 {
    width: 9%;
  }
}

@media (min-width: 2700px) {
  .col-xxl-2 {
    width: 8.5%;
  }
}

@media (min-width: 2900px) {
  .col-xxl-2 {
    width: 8%;
  }
}

@media (min-width: 3000px) {
  .col-xxl-2 {
    width: 7.5%;
  }
}
</style>
